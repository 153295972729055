<template>
  <div>
    <div class="font-weight-boldest mb-12">
      <h1 class="font-weight-boldest title mb-3 mt-15 mt-lg-0">
        Create New Password
      </h1>
      <p class="subtitle">Run, Manage & Scale your eCommerce</p>
    </div>
    <b-form @submit="submitCreatePassword">
      <div>
        <div class="mt-10">
          <b-input-group class="input-group">
            <b-input-group-append>
              <div class="mt-5 mt-lg-6 ml-3">
                <span class="svg-icon">
                  <v-icon size="24">mdi-lock</v-icon>
                </span>
              </div>
            </b-input-group-append>
            <b-form-input
              required
              :type="passwordHidden ? 'password' : 'text'"
              name="password"
              id="password"
              class="password-input"
              ref="password"
              placeholder="Password"
              v-model="password"
              :state="isPasswordValid"
            >
            </b-form-input>
            <b-input-group-prepend
              @click="passwordHidden = !passwordHidden"
              class="password-prepend"
            >
              <div class="mt-5 mt-lg-mt-6 mr-5">
                <span class="svg-icon">
                  <v-icon size="20"
                    >mdi-{{
                      passwordHidden ? "eye-off-outline" : "eye-outline"
                    }}</v-icon
                  >
                </span>
              </div>
            </b-input-group-prepend>
          </b-input-group>
        </div>
        <div class="mt-10">
          <b-input-group class="input-group">
            <b-input-group-append>
              <div class="mt-5 mt-lg-6 ml-3">
                <span class="svg-icon">
                  <v-icon size="24">mdi-lock</v-icon>
                </span>
              </div>
            </b-input-group-append>
            <b-form-input
              required
              :type="confirmPasswordHidden ? 'password' : 'text'"
              name="confirm-password"
              id="confirm-password"
              class="password-input"
              ref="confrim-password"
              placeholder="Confirm Password"
              v-model="confirmPassword"
            >
            </b-form-input>
            <b-input-group-prepend
              @click="confirmPasswordHidden = !confirmPasswordHidden"
              class="password-prepend"
            >
              <div class="mt-5 mt-lg-mt-6 mr-5">
                <span class="svg-icon">
                  <v-icon size="20"
                    >mdi-{{
                      confirmPasswordHidden ? "eye-off-outline" : "eye-outline"
                    }}</v-icon
                  >
                </span>
              </div>
            </b-input-group-prepend>
          </b-input-group>
          <p v-if="!isPasswordMatched" class="mt-3 text-danger">
            Password didn't mached.
          </p>
        </div>
      </div>
      <div
        class="d-flex submit-button-container justify-content-center mb-14 mt-14 p-1"
      >
        <button
          type="submit"
          ref="submit"
          class="submit-button m-1 font-weight-bolder"
          :class="[
            !isSubmitButtonEnable ? 'v-btn--disabled opacity-30' : '',
            loading ? ' loading' : '',
          ]"
        >
          <template v-if="loading">
            Submitting...
            <v-progress-circular
              :size="20"
              width="3"
              color="primary"
              indeterminate
              class="ml-2 mb-1"
            ></v-progress-circular>
          </template>
          <template v-else>Submit</template>
        </button>
      </div>
    </b-form>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "CreatePassword",

  data: () => ({
    password: "",
    confirmPassword: "",
    passwordHidden: true,
    confirmPasswordHidden: true,
    loading: false,
  }),

  computed: {
    isPasswordValid: function () {
      return this.password.length >= 8;
    },

    isPasswordMatched: function () {
      return this.password === this.confirmPassword;
    },

    isSubmitButtonEnable: function () {
      return this.isPasswordMatched && this.isPasswordValid;
    },
  },

  methods: {
    async submitCreatePassword(event) {
      event.preventDefault();

      this.loading = true;
      const data = { token: this.$route.query.token, password: this.password };

      ApiService.post("/auth/password/update", data)
        .then((data) => {
          if (data.data.success) {
            Swal.fire({
              title: "Success",
              text: data.data.message,
              icon: "success",
              showConfirmButton: true,
              timer: 7000,
            });
          }
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 2000);
        })
        .catch(() => {
          this.password = "";
          this.confirmPassword = "";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/pages/auth/generate-password.scss";
</style>
